body {
  font-family: 'Poppins', sans-serif;
}

.header {
  max-width: 1352px;
  margin: 2em auto 2em;
  overflow: hidden;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu li {
  margin-right: 20px;
}

.menu li:last-child {
  margin-right: 0;
}

.menu li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.menu li.active-tab a {
  color: #baf667;
}

.join-us-btn {
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #baf667;

}

.logo {
  width: 80px;
  height: auto;
}

