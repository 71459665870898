.header-component {
    display: flex;
    position: relative;
    /* height: 80px; */
}

.header-choice {
    background: linear-gradient(180deg, #5151C6, #BAF667);
    color: white !important;
    border-radius: 10px;
    border-radius: 40px;
}

.header-icon-account {
    color: white;
}