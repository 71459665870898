/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* .MuiDrawer-paper {
    background-color: rgba(186, 246, 103, 0.73) !important;
    border-radius: 15px;
    height: auto !important;
    margin-top: 5%;
    margin-left: 2%;
} */

/* .list_menu_choice:hover {
.list_menu_choice:hover {
    background: linear-gradient(45deg, #5151C6, #BAF667);
    color: white !important;
    border-radius: 10px;
}

.active .menuicon {
    background: linear-gradient(45deg, #5151C6, #BAF667);
    border-radius: 10px;
    color: white;
}

.active .dashboardicon {
    color: white !important;
} */


.itmebebe {
    position: relative;
    background-color: rgba(186, 246, 103, 0.73) !important;
    border-radius: 15px;
    margin-top: 70px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
}

.menuicon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-component {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
}
.header-component {
    display: flex;
    position: relative;
    /* height: 80px; */
}

.header-choice {
    background: linear-gradient(180deg, #5151C6, #BAF667);
    color: white !important;
    border-radius: 10px;
    border-radius: 40px;
}

.header-icon-account {
    color: white;
}
.introduce-component {
    display: flex;
    background: linear-gradient(45deg, #BAF667, white);
    border-radius: 15px;
    width: 100%;
}

.introduce-component h3 {
    width: 224px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #C91416;
    flex: left 1;
    margin-left: 2%;
    flex-grow: 7;
    margin-top: 2%;
}

.information {
    display: flex;
    flex-grow: 3;
    margin-right: 2px;
}

.just-now {
    line-height: 3;
    font-size: small;
    height: 14px;
    font-family: 'Roboto';
    color: #808080;
}

.name {
    line-height: 2;
    height: 19px;
    font-family: 'Roboto';
    color: #191919;
    flex: none;
    font-weight: bold;
    margin-right: 50px;
}

.email {
    height: 19px;
    font-family: 'Roboto';
    color: #191919;
    line-height: 2;
    font-weight: bold;
}

.from {
    margin-left: 20px;
    margin-right: 2px;
    width: 38px;
    height: 19px;
    line-height: 2;
    font-family: 'Roboto';
    color: #FA9E93;
}

.country {
    width: 71px;
    height: 19px;
    font-family: 'Roboto';
    line-height: 2;
    color: #191919;
    font-weight: bold;
}




.service-section {
    display: flex;
    flex-direction: column; 
  }
  
.service-section h1 {
    margin-left: 72px; 
    margin-top: 40px;
    margin-bottom: 40px;
}
  
.service-columns {
    display: flex;
    justify-content: space-around;
    margin-left: 72px; 

}
  
.service-column {
    flex: 1 1;
    padding: 20px;
    box-sizing: border-box;
}
  
.service-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 8px;
}
  
.service-section-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;
  }
  
  .service-heading {
    margin-top: 20px;
    font-weight: bold;
  }
  
  .service-columns-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .service-column-mobile {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .service-image-mobile {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .service-content-mobile {
    text-align: center;
  }
  
  .service-title-mobile {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .service-description-mobile {
    font-size: 16px;
    margin-top: 5px;
  }
  
.service-section {
    display: flex;
    flex-direction: column;
}

.service-section h1 {
    margin-left: 72px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.service-columns {
    display: flex;
    justify-content: space-around;
    margin-left: 72px;

}

.service-column {
    flex: 1 1;
    padding: 20px;
    box-sizing: border-box;
}

.service-image {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 8px;
}
.app-section-mobile {
  position: relative;
}

.center-column-mobile {
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 40px;
  font-size: 12px;
}
.app-image-container{
  margin-top: 0.83em;
}
.center-column-mobile h1 {
  border-bottom: 1px solid #000;
  text-align: center;
}

.center-column-mobile p {
  text-align: justify;
}

.app-image-container,
.center-column-mobile {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.arrow-button1 {
  top: 250px;
  position: absolute;
}

.back-button1 {
  left: 0;
}

.next-button1 {
  right: 0;
}

.follow-us-section {
    display: flex;
    margin-top: 60px;
    margin-left: 72px;
    flex-direction: column; 
    margin-bottom: 80px;
}

.follow-us-section h1 {
    font-family: 'Poppins';
    font-size: 56px;
    margin-bottom: 10px;
}

.image-container {
    justify-content: center;
    align-items: center;
    margin-top: 40px; 
  }
  
.image-container img {
    width: 100%;
    max-width: 300px;
    height: 300px; 
    margin-right: 50px;
}
.follow-us-section-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;  
    position: relative;
  }
  
  .follow-us-image-mobile {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
  }
  
body {
  font-family: 'Poppins', sans-serif;
}

.header {
  max-width: 1352px;
  margin: 2em auto 2em;
  overflow: hidden;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu li {
  margin-right: 20px;
}

.menu li:last-child {
  margin-right: 0;
}

.menu li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.menu li.active-tab a {
  color: #baf667;
}

.join-us-btn {
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #baf667;

}

.logo {
  width: 80px;
  height: auto;
}


.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dbdddca1;
    padding: 0 20px; 
    height: 80px;
  }
  
 
  
body {
  font-family: 'Poppins', sans-serif;
  margin: 0; 
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  border-top: 0.5px solid #000;
  margin-top: auto; 
  padding: 20px; 
}

.container {
  max-width: 1352px;
  margin: auto; 
}

.footer-logo {
  width: 100px;
  height: auto;
}

.contact-info {
  text-align: left;
}

.contact-info a {
  margin-right: 10px;
  text-decoration: none;

}

.contact-info svg {
  vertical-align: middle;
}


.scroll-to-top-btn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #baf667;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .scroll-to-top-btn.visible {
    display: block;
  }
  
 
  
.app-section {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    margin-left: 72px;
    margin-right: 0px;
}

.left-column,
.right-column {
    flex: 1 1;
}

.app-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 8px;
}

.center-column {
    flex: 1 1;
    padding: 60px;
    box-sizing: border-box;
    margin-right: 60px;
}

.center-column h1 {
    font-family: 'Poppins';
    font-size: 56px;
    margin-bottom: 10px;
    border-bottom: 2px solid #000;
    text-align: center;
}

.center-column p {
    font-family: 'Poppins';
    font-size: 18px;
    margin-top: 20px;
    line-height: 1.8;
}

.app-manual {
    /* margin-top: -10%; */
    margin-left: -20%;
    width: 150%;
    max-width: 700px;
    height: auto;
    border-radius: 8px;
}
.service-section-mobile-manual {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;
    height: 500px;
}

.service-heading {
    margin-top: 20px;
    font-weight: bold;
}

.service-columns-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-column-mobile {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-image-mobile {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.service-content-mobile {
    text-align: center;
}

.service-title-mobile {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

.service-description-mobile {
    font-size: 16px;
    margin-top: 5px;
}
.app-section {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    margin-left: 72px;
    margin-right: 0px;
}

.left-column,
.right-column {
    flex: 1 1;
}

.app-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 8px;
}

.center-column {
    flex: 1 1;
    padding: 60px;
    box-sizing: border-box;
    margin-right: 60px;
}

.center-column h1 {
    font-family: 'Poppins';
    font-size: 56px;
    margin-bottom: 10px;
    border-bottom: 2px solid #000;
    text-align: center;
}

.center-column p {
    font-family: 'Poppins';
    font-size: 18px;
    margin-top: 20px;
    line-height: 1.8;
}

.app-manual {
    /* margin-top: -10%; */
    margin-left: -20%;
    width: 150%;
    max-width: 700px;
    height: auto;
    border-radius: 8px;
}
.service-section-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;
}

.service-heading {
    margin-top: 20px;
    font-weight: bold;
}

.service-columns-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-column-mobile {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-image-mobile {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.service-content-mobile {
    text-align: center;
}

.service-title-mobile {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

.service-description-mobile {
    font-size: 16px;
    margin-top: 5px;
}
.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
}
  
.content-column {
    flex: 1 1;
    line-height: 1.5;
    margin-left: 72px;
}
  
.content-column2 {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 72px;
}

.story-image {
    width: 100%; 
    height: auto; 
    margin-bottom: 20px; 
}

.story-section-mobile {
    text-align: center;
  }
  
  .story-image-mobile {
    width: 100%;
    height: auto;
  }
  
  .content-mobile {
    display: flex;
    flex-direction: space-between;;
    align-items: center;
    width: 100%; 
  }
  
  .content-column-mobile {
    margin-bottom: 20px;
  }
  
  .content-column2-mobile {
    text-align: justify;
    padding: 10px 20px;
  }
  
.policy-container {
  max-width: 1400px;
  padding: 80px;
  background: url(/static/media/about2.e9af379e.jpg) center/cover; 
  margin: 0 auto; 
}

.policy-container h1 {
  text-align: center;
  color: #baf667; 
}

.policy-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  line-height: 1.5;
}

.policy-column {
  width: 45%;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
}

.policy-column:nth-child(2) {
  margin-left: 20px; 
}

.policy-container p {
  color: #000; 
}

  
.policy-container-mobile {
    padding: 20px;
    background: url(/static/media/about2.e9af379e.jpg) center/cover;
  }
  
  .policy-container-mobile h1 {
    text-align: center;
    color: #baf667;
    font-size: 20px;
  }
  
  .policy-content-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    line-height: 1.5;
    font-size: 10px;
  }
  
  .policy-column-mobile {
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
  }
.policy-column-mobile p{
    padding: 10px;
}
  
.MuiDialog-root {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.MuiDialog-container {
  max-width: 90%;
  max-height: 90vh; 
  width: auto !important;
  overflow: hidden;
}

.MuiPaper-root {
  margin: 0;
  max-width: 100%;
  overflow: hidden; 
}

.ImageList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}

.ImageList img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.ImageList img:hover {
  transform: scale(1.1);
  overflow: hidden;
}
.porfolio{
  overflow: hidden;
}
.portfolio-mobile {
    padding: 16px;
    overflow: hidden;
  }
  
  .ImageList-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .ImageList-mobile img {
    width: 100%;
    height: auto;
  }
  
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}





.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.login-container {
    display: flex;
    background-image: url(/static/media/421386197_402829812197603_6075397259727844978_n.fabc93da.png);
    overflow: hidden;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-size: 100%;
    background-position: center;
}

.form-container {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    z-index: 1;
    margin-left: 60%;
}

input {
    width: 80%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 10px;
}

.login_btn {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 100px;
    height: 50px;
    background: linear-gradient(87.84deg, #5151C6 10.73%, rgba(186, 246, 103, 0) 169.14%);
    border-radius: 15px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 0px;
}

.login_btn:hover {
    background-color: #45a049;
}

.login_h2 {
    font-family: 'SVN-Product Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #FFFFFF;
}

.signin {
    font-family: 'SVN-Product Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: rgba(186, 246, 103, 0.73);
}

.logologin {
    width: 20%;
    /* filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1); */
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.btn-success {
    border: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 45px;
    height: 45px;
    left: 995px;
    top: 82px;
    width: 30px;
    height: 30px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 7px;
    margin-left: 45%;
}

.select-activity {
    margin-left: 5%;
}

.MuiAutocomplete-inputRoot {
    border-radius: 100px !important;
}
.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
