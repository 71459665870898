.policy-container {
  max-width: 1400px;
  padding: 80px;
  background: url('../../../../img/about2.jpg') center/cover; 
  margin: 0 auto; 
}

.policy-container h1 {
  text-align: center;
  color: #baf667; 
}

.policy-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  line-height: 1.5;
}

.policy-column {
  width: 45%;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
}

.policy-column:nth-child(2) {
  margin-left: 20px; 
}

.policy-container p {
  color: #000; 
}

  