.app-section-mobile {
  position: relative;
}

.center-column-mobile {
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 40px;
  font-size: 12px;
}
.app-image-container{
  margin-top: 0.83em;
}
.center-column-mobile h1 {
  border-bottom: 1px solid #000;
  text-align: center;
}

.center-column-mobile p {
  text-align: justify;
}

.app-image-container,
.center-column-mobile {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.arrow-button1 {
  top: 250px;
  position: absolute;
}

.back-button1 {
  left: 0;
}

.next-button1 {
  right: 0;
}
