.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dbdddca1;
    padding: 0 20px; 
    height: 80px;
  }
  
 
  