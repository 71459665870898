.MuiDialog-root {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.MuiDialog-container {
  max-width: 90%;
  max-height: 90vh; 
  width: auto !important;
  overflow: hidden;
}

.MuiPaper-root {
  margin: 0;
  max-width: 100%;
  overflow: hidden; 
}

.ImageList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}

.ImageList img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.ImageList img:hover {
  transform: scale(1.1);
  overflow: hidden;
}
.porfolio{
  overflow: hidden;
}