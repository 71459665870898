.follow-us-section-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;  
    position: relative;
  }
  
  .follow-us-image-mobile {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
  }
  