.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
}
  
.content-column {
    flex: 1;
    line-height: 1.5;
    margin-left: 72px;
}
  
.content-column2 {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 72px;
}

.story-image {
    width: 100%; 
    height: auto; 
    margin-bottom: 20px; 
}
