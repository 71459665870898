.app-section {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    margin-left: 72px;
    margin-right: 0px;
}

.left-column,
.right-column {
    flex: 1;
}

.app-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 8px;
}

.center-column {
    flex: 1;
    padding: 60px;
    box-sizing: border-box;
    margin-right: 60px;
}

.center-column h1 {
    font-family: 'Poppins';
    font-size: 56px;
    margin-bottom: 10px;
    border-bottom: 2px solid #000;
    text-align: center;
}

.center-column p {
    font-family: 'Poppins';
    font-size: 18px;
    margin-top: 20px;
    line-height: 1.8;
}

.app-manual {
    /* margin-top: -10%; */
    margin-left: -20%;
    width: 150%;
    max-width: 700px;
    height: auto;
    border-radius: 8px;
}