.update-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    background-color: #5858FA;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 74px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}