body {
  font-family: 'Poppins', sans-serif;
  margin: 0; 
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  border-top: 0.5px solid #000;
  margin-top: auto; 
  padding: 20px; 
}

.container {
  max-width: 1352px;
  margin: auto; 
}

.footer-logo {
  width: 100px;
  height: auto;
}

.contact-info {
  text-align: left;
}

.contact-info a {
  margin-right: 10px;
  text-decoration: none;

}

.contact-info svg {
  vertical-align: middle;
}
